.footer-main {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.footer-main-1 {
  position: relative;
  bottom: 0;
  width: 100%;
}
.footer-divider-first {
  background: linear-gradient(
    0.25turn,
    #6CC383 0%,
    #fef84a 34.15%,
    #59ffa5 69.05%,
    #6CC383 100%
  );
  height: 23px;
}

.footer-wrapper {
  background-color: #0f0a2c;
  text-align: center;
  padding: 4rem;
  height: 10rem;
}

.footer-content {
  font-family: "JetBrains Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
}

.footer-login {
  background: linear-gradient(
    90deg,
    #6CC383 0%,
    #fbbb00 34.15%,
    #28e17d 55.87%,
    #6CC383 100%
  );
  height: 85px;
}
.learn {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  color: #358149;
  padding: 1.8rem 12rem;
}
/* media */
@media only screen and (max-width: 600px) {
  .learn {
    padding: 0.8rem 2rem;
  }
  .footer-wrapper {
    padding: 2rem 3px;
    height: 99px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 950px) {
  .learn {
    padding: 1.8rem 5rem;
  }
}
