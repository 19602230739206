.arrivals-container {
  background-color: #efebfb;
  padding-bottom: 40px;
}
.add-to-cart-btn {
  border-color: #6CC383;
  padding: 0px 18px;
  font-family: "Barlow";
  font-weight: 700;
  font-size: 12px;
  color: #6CC383;
  height: 24px;
  background: #efebfb;
  line-height: 14px;
}
.add-btn:hover {
  border-color: #6CC383 !important;
}
.btn-wrap {
  display: none;
}
@media only screen and (max-width: 600px) {
  .btn-wrap {
    display: block;
  }
  .arrivals-container {
    padding-bottom: 1px;
  }
}
