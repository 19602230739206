.main-wrap {
  background-color: #efebfb;
  padding: 1px;
}
.checkout-container {
  padding: 0px 16rem;
}

.checkout-text {
  font-family: "Barlow";
  font-weight: 800;
  font-size: 42px;
  line-height: 46px;
  color: #6CC383;
}
.billing-address-form {
  background: #ffffff;
  box-shadow: 12px 12px 20px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  width: 80%;
  margin-bottom: 6rem;
}
.form-billing {
  padding: 19px 24px;
}
.title-billing {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 16px;
}
.form-wrap {
  display: flex;
  align-items: center;
  gap: 11px;
}
.form {
  position: relative;
  width: 100%;
  height: 3rem;
  margin-bottom: 12px;
}

.form__input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 44px;
  background: #ffffff;
  border: 1px solid #b2bcca;
  border-radius: 4px;
  color: black;
  outline: none;
  padding: 12px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
}
/* input:hover {
  border-color: red;
} */

/* Change border when input focus*/

/* input:focus {
  border-color: pink;
} */

.form__label {
  position: absolute;
  left: 1rem;
  top: 13px;
  cursor: text;
  transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
  background-color: white;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #828282;
}

.form__input:focus ~ .form__label,
.form__input:not(:placeholder-shown).form__input:not(:focus) ~ .form__label {
  top: -0.5rem;
  left: 0.8rem;
  padding: 0px 4px;
}
.select {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 44px;
  background: #ffffff;

  border-radius: 4px;

  outline: none;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background: #ffffff;
  border: 1px solid #b2bcca;
  border-radius: 4px;
  height: 44px;
  padding: 5px 11px;
}
.check {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4f4f4f;
  left: 0px;
}
.footer-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}
.back {
  width: 100%;
}
.back-btn {
  background: #ffffff;
  border: 1px solid #6CC383;
  border-radius: 5px;
  font-family: "Barlow";
  font-weight: 700;
  font-size: 16px;
  color: #6CC383;
  line-height: 0;
}
.continue {
  background: #4afb9c;
  border-radius: 5px;
  font-family: "Barlow";
  font-weight: 700;
  font-size: 16px;
  color: #6CC383;
  line-height: 0;
}
.error-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  width: 90%;
  margin-top: -12px;
  margin-bottom: 10px;
  color: red;
}
.error-fname {
  margin-left: 1rem;
}
.error-email {
  font-size: 14px;
  color: red;
  margin-left: 13px;
}
/* media */

@media only screen and (max-width: 600px) {
  .checkout-container {
    padding: 0px;
  }
  .checkout-text {
    text-align: center;
  }
  .billing-address-form {
    margin-left: 1.5rem;
    width: 87%;
  }
  .form-wrap {
    flex-direction: column;
    gap: 0;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
  .checkout-container {
    padding: 0px 10rem;
  }
  .form-bill {
    width: 100% !important;
  }
  .billing-address-form {
    width: 87%;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1300px) {
  .billing-address-form {
    width: 65%;
  }
}
