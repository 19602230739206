.main-flow-container {
  display: flex;
  justify-content: space-between;
}
.left-flow {
  width: 46%;
  padding: 9px;
  /* background: #0f0a2c; */
}
.right-flow {
  flex: 1;
  background: #0c0824;
  padding: 14px;
  margin-top: -5.3rem;
  width: 54%;
}
.inner-div {
  border: 0.931707px dashed #f90c0c;
  /* height: 83vmin; */
  height: 100%;
}
.img-flow {
  height: 14.1rem !important;
}
.btn-learn {
  background: #6CC383;
  border-radius: 5px;
  border-color: #6CC383;
  font-family: "JetBrains Mono";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
  width: 47%;
  height: 40px;
}
.btn-mobile-view {
  display: none;
}
.btn-api {
  background: #6CC383;
  border-radius: 5px;
  border-color: #6CC383;
  font-family: "JetBrains Mono";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
  height: 40px;
}
@media only screen and (max-width: 600px) {
  .main-flow-container {
    flex-direction: column;
  }
  .left-flow {
    width: 100%;
    padding: 16px 0;
  }
  .right-flow {
    margin-top: -22px;
    margin-bottom: 10px;
    background: #0f0a2c;
    width: 100%;
    padding: 16px 0;
  }
  .btn-desktop-view {
    display: none;
  }
  .btn-mobile-view {
    display: block;
  }
  .btn-learn {
    width: 100%;
  }
  .btn-api {
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1000px) {
  .left-flow {
    padding: 16px 0;
  }
  .right-flow {
    padding: 16px 0;
  }
  /* .inner-div {
    height: 45vmin;
  } */
  .img-flow {
    height: 16.1rem !important;
  }
}
